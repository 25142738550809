import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "text-inputs-enable-the-user-to-interact-with-and-input-content-and-data-this-component-can-be-used-for-long-and-short-form-entries"
    }}>{`Text inputs enable the user to interact with and input content and data. This component can be used for long and short form entries.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Format</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "44.285714285714285%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "An example of a text input.",
        "title": "An example of a text input.",
        "src": "/static/bc1745eae6c5a7c39ace85168bfbf065/fb070/text-input-header-usage.png",
        "srcSet": ["/static/bc1745eae6c5a7c39ace85168bfbf065/d6747/text-input-header-usage.png 288w", "/static/bc1745eae6c5a7c39ace85168bfbf065/09548/text-input-header-usage.png 576w", "/static/bc1745eae6c5a7c39ace85168bfbf065/fb070/text-input-header-usage.png 1152w", "/static/bc1745eae6c5a7c39ace85168bfbf065/fb104/text-input-header-usage.png 1728w", "/static/bc1745eae6c5a7c39ace85168bfbf065/8fefe/text-input-header-usage.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`There instances, sometimes in the same form, where you need users to enter both short and long form content. Allow the size of the text input box to reflect the length of the content you expect the user to enter.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When the expected user input is a single line of text, as opposed to a paragraph.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text area`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When the expected user input is more than one sentence.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <h3 {...{
      "id": "default-values"
    }}>{`Default values`}</h3>
    <p>{`Where possible, add programmatic assistance. Detect and pre-fill inputs to reduce errors and save time. When the software can’t determine the value that belongs in an input, use type-ahead to make suggestions. Use sentence-case for default values, detected values, and auto-completion text.`}</p>
    <h3 {...{
      "id": "validation-and-errors"
    }}>{`Validation and errors`}</h3>
    <p>{`Real time validation helps to streamline the process and keep data clean when the user is filling out forms. For full guidelines, refer to the `}<a parentName="p" {...{
        "href": "/components/form/usage"
      }}>{`forms`}</a>{` usage page.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "33.016304347826086%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Validation text example",
        "title": "Validation text example",
        "src": "/static/a7d4ccbdf1c62e7f20351f471b0ceb96/fb070/text-input-usage-2.png",
        "srcSet": ["/static/a7d4ccbdf1c62e7f20351f471b0ceb96/d6747/text-input-usage-2.png 288w", "/static/a7d4ccbdf1c62e7f20351f471b0ceb96/09548/text-input-usage-2.png 576w", "/static/a7d4ccbdf1c62e7f20351f471b0ceb96/fb070/text-input-usage-2.png 1152w", "/static/a7d4ccbdf1c62e7f20351f471b0ceb96/c3e47/text-input-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "character-count"
    }}>{`Character count`}</h3>
    <p>{`Text fields and text areas may have a character limit and counter. Use the character limit and counter to let users know how long their entry can be before they begin typing.`}</p>
    <p>{`While useful for data collection, character count is not an intuitive way to communicate content length to users. Character count allows a user to understand an input error if they surpass the character limit.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "41.30434782608695%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "An example of a text area.",
        "title": "An example of a text area.",
        "src": "/static/d2c4d72470379d3ea77194383a806b23/fb070/text-area-usage-1.png",
        "srcSet": ["/static/d2c4d72470379d3ea77194383a806b23/d6747/text-area-usage-1.png 288w", "/static/d2c4d72470379d3ea77194383a806b23/09548/text-area-usage-1.png 576w", "/static/d2c4d72470379d3ea77194383a806b23/fb070/text-area-usage-1.png 1152w", "/static/d2c4d72470379d3ea77194383a806b23/c3e47/text-area-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h3 {...{
      "id": "labels"
    }}>{`Labels`}</h3>
    <p>{`Effective form labeling helps users understand what information to enter into a text input. Using placeholder text in lieu of a label is sometimes employed as a space-saving method. However, this is not recommended because it hides context and presents accessibility issues.`}</p>
    <h4 {...{
      "id": "accessibility-best-practices-for-labels"
    }}>{`Accessibility best practices for labels:`}</h4>
    <ul>
      <li parentName="ul">{`Labels must be visible when an input gets focus.`}</li>
      <li parentName="ul">{`Labels must be announced to the screen reader on focus.`}</li>
      <li parentName="ul">{`Ensure the helper text that appears under an input is read when an assistive technology user stops at an input using ARIA.`}</li>
    </ul>
    <h3 {...{
      "id": "placeholder-text"
    }}>{`Placeholder text`}</h3>
    <p>{`Placeholder text provides hints or examples of what to enter. Placeholder text disappears after the user begins entering data into the input and should not contain crucial information. Use sentence-style capitalization, and in most cases, write the text as a direct statement without punctuation.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.434782608695656%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Placeholder text example",
        "title": "Placeholder text example",
        "src": "/static/a65c3594c3b9f17f365773cff7c3345f/fb070/text-input-usage-1.png",
        "srcSet": ["/static/a65c3594c3b9f17f365773cff7c3345f/d6747/text-input-usage-1.png 288w", "/static/a65c3594c3b9f17f365773cff7c3345f/09548/text-input-usage-1.png 576w", "/static/a65c3594c3b9f17f365773cff7c3345f/fb070/text-input-usage-1.png 1152w", "/static/a65c3594c3b9f17f365773cff7c3345f/c3e47/text-input-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "help-text"
    }}>{`Help text`}</h3>
    <p>{`Help text is pertinent information that assists the user in completing a field. Help text is always available when the input is focused and appears underneath the label. Use sentence-style capitalization, and in most cases, write the text as full sentences with punctuation.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "32.880434782608695%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Help text example",
        "title": "Help text example",
        "src": "/static/2fba543d98a62d85adf6fe6cf5bb2557/fb070/text-input-usage-3.png",
        "srcSet": ["/static/2fba543d98a62d85adf6fe6cf5bb2557/d6747/text-input-usage-3.png 288w", "/static/2fba543d98a62d85adf6fe6cf5bb2557/09548/text-input-usage-3.png 576w", "/static/2fba543d98a62d85adf6fe6cf5bb2557/fb070/text-input-usage-3.png 1152w", "/static/2fba543d98a62d85adf6fe6cf5bb2557/c3e47/text-input-usage-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      